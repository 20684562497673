










import { Component, Vue } from 'vue-property-decorator';
import MainHeader from '@/shared/resources/components/MainHeader.vue';
import UserProfile from '@/app/modules/users/components/UserProfile.vue';

@Component({
  components: {
    UserProfile,
    MainHeader,
  },
})
export default class ProfileView extends Vue {
}
