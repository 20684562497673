






























import { Component, Vue } from 'vue-property-decorator';

import GridRow from '@/shared/resources/components/grid/GridRow.vue';
import GridCol from '@/shared/resources/components/grid/GridCol.vue';
import UserCoreModel from '@/modules/users/models/UserCoreModel';
import AppSettings from '@/app/lib/settings/AppSettings';
import ContractorContactData from '@/app/modules/contactors/components/partials/ContractorContactData.vue';
import Card from '@/shared/resources/components/cards/Card.vue';
import ContractorInvoiceData from '@/app/modules/contactors/components/partials/ContractorInvoiceData.vue';
import ContractorAddressData from '@/app/modules/contactors/components/partials/ContractorAddressData.vue';
import Loader from '@/shared/resources/components/Loader.vue';

@Component({
  components: {
    Loader,
    ContractorAddressData,
    ContractorInvoiceData,
    ContractorContactData,
    Card,
    GridCol,
    GridRow,
  },
})
export default class UserProfile extends Vue {
  /**
   * Getters
   */
  private get user(): UserCoreModel | null {
    return AppSettings.user;
  }

  /**
   * Display getters
   */
  private get displayDetails(): boolean {
    return !!this.user;
  }

  private get displayLoader(): boolean {
    return !this.user;
  }
}
